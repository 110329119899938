import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

require('jquery.easing');

import ScrollMagic from 'scrollmagic';
import Swiper from 'swiper';
import imagesLoaded from 'imagesloaded';

$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

Number.prototype.clamp = function(min, max) {
	return Math.min(Math.max(this, min), max);
};

//Navbar scrolled
$(function () {
    var header = $('header'),
        w = $(window);

    function stickyNav() {
        if (w.scrollTop() >= 123) {
            header.addClass('header-scrolled');
        } else {
            header.removeClass('header-scrolled');
        }
    }

    stickyNav();
    w.on('scroll', stickyNav);
});

// Navbar menu
$(function () {
    var menu = $('.navbar-menu'),
        menuNav = $('.navbar-menu .navbar-nav'),
        trigger = $('[data-trigger="navbar-menu"]'),
        close = $('[data-dismiss="navbar-menu"]'),
        body = $('body');

    var a, b;

    function toggleMenu(e) {
        e.preventDefault();

        if (!body.hasClass('navbar-menu-is-open')) {

            body.addClass('navbar-menu-is-open');
            menu.addClass('navbar-menu-open');

            a = setTimeout(function () {
                $('.navbar-nav .nav-item').css({
                    transform: 'none',
                });
            }, 50);

        } else {

            body.removeClass('navbar-menu-is-open');
            menu.removeClass('navbar-menu-open');

            $('.navbar-nav .nav-item').css({
                transform: '',
            });

        }
    }

    trigger.on('click', toggleMenu);
    close.on('click', toggleMenu);
});

// Load single div backgrounds
$(function () {
    var image = document.querySelectorAll('img[data-bg="true"], [data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';

                if ($(image[n]).parent().is('.bg')) {
                    $(image[n]).parent().css({
                        opacity: '1'
                    });
                }
            }

            function imageNotFound() {
                // image[n].parentNode.style.backgroundColor = '#F3F5F7';
            }
        }(i));
    }
});

// Google Maps
$(function () {

    function initialize() {
        var map_canvas = document.getElementById('googleMap');

        var map_options = {
            center: new google.maps.LatLng(41.886070, -87.648948),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            // scrollwheel: false,
            draggable: true
        };

        // var image = {
        //     path: "M10,5c0,2.8-2.2,5-5,5S0,7.8,0,5s2.2-5,5-5S10,2.2,10,5z",
        //     fillColor: '#ffffff',
        //     fillOpacity: 1,
        //     anchor: new google.maps.Point(5, 5),
        //     strokeWeight: 0,
        //     scale: 1
        // };

        var image = {
            url: 'img/map-pin.png',
            scaledSize: new google.maps.Size(32, 45),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(-8, 45),
        };

        var map = new google.maps.Map(map_canvas, map_options);

        var locations = [
            ['208 N Green St. \n' +
            'Chicago, IL 60607\n', 41.886070, -87.648948],
        ];

        var infowindow = new google.maps.InfoWindow();

        var marker, i;

        for (i = 0; i < locations.length; i++) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
                icon: image,
                title: locations[i][0]
            });
            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    infowindow.setContent(locations[i][0]);
                    infowindow.open(map, marker);
                }
            })(marker, i));
        }

        var styles = [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#212121"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#212121"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.locality",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#181818"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#1b1b1b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#2c2c2c"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#8a8a8a"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#373737"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#3c3c3c"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#4e4e4e"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#3d3d3d"
                    }
                ]
            }
        ];
        map.setOptions({styles: styles});
    }

    // google.maps.event.addDomListener(window, 'load', initialize);

    function loadScript(src, callback) {
        //Unload gogole maps api
        if (window.google !== undefined && google.maps !== undefined) {
            delete google.maps;
            $('script').each(function () {
                if (this.src.indexOf('googleapis.com/maps') >= 0
                    || this.src.indexOf('maps.gstatic.com') >= 0
                    || this.src.indexOf('earthbuilder.googleapis.com') >= 0) {
                    // console.log('removed', this.src);
                    $(this).remove();
                }
            });
        }

        //Load google maps api
        var script = document.createElement("script");
        script.type = "text/javascript";
        if (callback) script.onload = callback;
        document.getElementsByTagName("head")[0].appendChild(script);
        script.src = src;
    }

    if ($('#googleMap').length > 0) {
        loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDYCsNb-USQrIoIOvDAAzkITEzuImujR14', initialize);
    }
});

// ScrollMagic
$(function () {

    const math = {
        lerp: (a, b, n) => {
            return (1 - n) * a + n * b;
        },
        norm: (value, min, max) => {
            return (value - min) / (max - min);
        }
    };

    // init controller
    var controller = new ScrollMagic.Controller();

    var slide = $('.people-data-content-slide'),
        slideTitle = $('.people-data-title').closest('li'),
        wrapper = $('#section-scroll');

    var sceneContentHolder = $('.people-data-content-holder');
    var scene;
    var sceneHeight;
    var scrollAmount;
    var menuPadding;

    function sceneInit() {
        sceneHeight = 0;

        let slideHeights = [0];

        imagesLoaded('.people-data-content-holder', function() {
            slide.each(function (i, el) {
                if (window.innerHeight <= 1024 && i === slide.length - 1) {
                    sceneHeight += $(el).outerHeight();
                } else {
                    sceneHeight += $(el).outerHeight(true);
                }

                slideHeights.push(sceneHeight);
            });

            menuPadding = $('.people-data-menu').offset().top - $('#section-scroll').offset().top;

            sceneHeight -= menuPadding * 1.5;

            sceneContentHolder.css({
                height: sceneHeight,
                paddingTop: menuPadding + 'px',
            });

            if (!wrapper.hasClass('has-scene')) {
                wrapper.addClass('has-scene');

                scene = new ScrollMagic.Scene({
                    triggerElement: '#section-scroll',
                    duration: sceneHeight,
                    triggerHook: 0,
                })
                    .setPin("#section-scroll")
                    .reverse(true)
                    .addTo(controller);

                scene.on('progress', function (event) {
                    scrollAmount = event.progress * sceneHeight;

                    slideTitle.find('a').removeClass('active');

                    if (event.progress >= slideHeights[3] / sceneHeight) {
                        slideTitle.eq(3).find('a').addClass('active');
                    } else if (event.progress >= slideHeights[2] / sceneHeight) {
                        slideTitle.eq(2).find('a').addClass('active');
                    } else if (event.progress >= slideHeights[1] / sceneHeight) {
                        slideTitle.eq(1).find('a').addClass('active');
                    } else {
                        slideTitle.eq(0).find('a').addClass('active');
                    }

                    $('.people-data-content-holder > div').css({
                        transform: 'translate3d(0, -' + scrollAmount + 'px, 0)',
                    });
                });
            } else {
                scene.refresh();
            }
        });

        slideTitle.on('click', function (e) {
            e.preventDefault();

            var that = $(this),
                currentIndex = that.index();

            let heightMod = 0;
            if (currentIndex) {
                heightMod = slide.eq(currentIndex).height() / 3;
            }

            controller.scrollTo(scene.triggerPosition() + slideHeights[currentIndex] + heightMod);

            slideTitle.find('a').removeClass('active');
            that.find('a').addClass('active');
        });
    }

    function initPeopleData() {
        if ($(window).width() > 1023) {
            sceneInit();
        } else {
            if (wrapper.hasClass('has-scene')) {
                wrapper.removeClass('has-scene');
                scene = scene.destroy(true);

                sceneContentHolder.css({
                    height: '',
                    paddingTop: '',
                });

                sceneContentHolder.find('>div').css({
                    transform: '',
                });
            }
        }
    }

    if (wrapper.length > 0) {
        initPeopleData();
        $(window).on('resize', initPeopleData);
    }
});

// Navbar active link
$(function () {
    var dot = $('.navbar-item-active-dot');
    var navLink = $('.navbar-nav .nav-item > .nav-link');
    var navLinkActive = $('.navbar-nav .nav-item > .nav-link.active');

    function setDotPosition() {
        if (navLinkActive.hasClass('btn')) {
            dot.css({
                opacity: '0',
            });
        } else {
            dot.css({
                transform: 'translate3d(' + (navLinkActive.offset().left + navLinkActive.innerWidth() / 2) + 'px, 0, 0)',
                opacity: '1',
            });
        }
    }

    setDotPosition();
    $(window).on('resize scroll', setDotPosition);

    navLink.on('mouseover', function (e) {
        var that = $(this);

        if (that.hasClass('btn')) {
            dot.css({
                opacity: '0',
            });
        } else {
            dot.css({
                transform: 'translate3d(' + (that.offset().left + that.innerWidth() / 2) + 'px, 0, 0)',
                opacity: '1',
            });
        }
    });

    navLink.on('mouseout', function (e) {
        if (navLinkActive.hasClass('btn')) {
            dot.css({
                transform: 'translate3d(' + (navLinkActive.parent().prev().find('.nav-link').offset().left + navLinkActive.parent().prev().find('.nav-link').innerWidth() / 2) + 'px, 0, 0)',
                opacity: '0',
            });
        } else {
            dot.css({
                transform: 'translate3d(' + (navLinkActive.offset().left + navLinkActive.innerWidth() / 2) + 'px, 0, 0)',
                opacity: '1',
            });
        }
    });

    $(window).on('activate.bs.scrollspy', function () {
        navLinkActive = $('.navbar-nav .nav-item > .nav-link.active');

        setDotPosition();
    });
});

// Scrollspy
$(function () {
    function scrollSpy() {
        $('body').scrollspy({
            target: '.navbar .navbar-nav',
            offset: $(window).width() > 767 ? 123 : 80,
        });
    }

    scrollSpy();
    $(window).on('resize', scrollSpy);
});

// Expandables
$(function () {
    var trigger = document.querySelectorAll('.people-data-content-slide > .people-data-title');

    function alwaysTriggerFirst() {
        if ($(window).width() < 768) {
            trigger[0].parentNode.querySelector('.process-card-frame').style.maxHeight = trigger[0].parentNode.querySelector('.process-card-frame').scrollHeight + 'px';
        }
    }

    alwaysTriggerFirst();
    $(window).on('resize', alwaysTriggerFirst);

    for (var i = 0; i < trigger.length; i++) {
        (function (n) {

            trigger[n].onclick = function () {
                var that = this;

                if ($(window).width() < 1023) {
                    $(trigger).removeClass('active');

                    $(trigger).parent().find('.process-card-frame').css({
                        maxHeight: '',
                    });

                    $(trigger).parent().find('.process-card-frame').removeClass('active');

                    if (!that.classList.contains('active')) {
                        that.classList.add('active');
                        that.parentNode.querySelector('.process-card-frame').classList.add('active');
                        that.parentNode.querySelector('.process-card-frame').style.maxHeight = that.parentNode.querySelector('.process-card-frame').scrollHeight + 'px';
                    } else {
                        that.classList.remove('active');
                        that.parentNode.querySelector('.process-card-frame').classList.remove('active');
                        that.parentNode.querySelector('.process-card-frame').style.maxHeight = '';
                    }
                }
            }
        })(i);
    }
});

// Swiper - Advantages
$(function () {
    var swiperWrapper = $('.swiper-advantages-wrapper');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-advantages', {
            slidesPerView: 1,
            spaceBetween: 15,
            pagination: {
                el: '.swiper-advantages-wrapper .swiper-pagination',
                clickable: true,
            },
        });
    }
});

// Smooth Scroll
$(function () {
    $('[data-scroll="smooth"], a[data-scroll="smooth"]').click(function (event) {
        if ($(window).width() < 767) {
            $('body').removeClass('navbar-menu-is-open');
            $('.navbar-menu').removeClass('navbar-menu-open');

            $('.navbar-nav .nav-item').css({
                transform: '',
            });
        }

        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $(target).attr('id') !== 'top' ? (target.offset().top - $('header').innerHeight()) + 2 : target.offset().top,
                }, 1000, 'easeInOutExpo');
            }
        }
    });
});

// Start video inline
$(function () {
    const trigger = $('a[data-toggle="video"], [data-toggle="video"]');

    var video;
    var player;
    var template;

    function startVideo(e) {
        e.preventDefault();

        var $this = $(this);

        function onYouTubeIframeAPIReady() {
            video = document.createElement('div');

            video.id = 'video';

            template = $('<div class="embed-responsive embed-responsive-16by9"></div>');

            template.appendTo($this);

            $(video).appendTo($this.find('.embed-responsive'));

            $this.addClass('video-playing');

            player = new YT.Player(video, {
                width: 1600,
                height: 900,
                videoId: $this.data('video-id'),
                events: {
                    onReady: function () {
                        player.playVideo();
                    }
                }
            });
        }

        onYouTubeIframeAPIReady();
    }

    trigger.on('click', startVideo);
});

// In viewport
$(function () {
    var element = document.querySelectorAll('[data-animate="true"]');
    var topLink = $("#topLink");
    var video = document.getElementById('heroVideo');
    var controller = new ScrollMagic.Controller();

    topLink.on("click", function() {
      video.pause();
      video.currentTime = 0;
      video.play();
    });

    for (var i = 0; i < element.length; i++) {
        (function (n) {
            var scene = new ScrollMagic.Scene({
                triggerElement: element[n],
                triggerHook: 'onEnter',
                duration: '100%',
                offset: 200,
            })
                .reverse(false)
                .addTo(controller);

            scene.on('enter', function () {
                element[n].classList.add('loaded');
            });
        }(i));
    }
});

// Autofill Email Message box
$(function () {
  let messageBody = $('.contact-card-content textarea');
  let messageRoot = 'Hi, I’m interested in your ';

  $('.pricing-card-footer a').click(function (el) {
    switch ($(el.currentTarget).data('message-option')) {
      case 1:
        messageBody.text(messageRoot + '3 day strategy session.');
        break;
      case 2:
        messageBody.text(messageRoot + 'remote 2 week sprint.');
        break;
      case 3:
        messageBody.text(messageRoot + 'remote 4 week sprint.');
        break;
    }
  });
});

function circleReset() {
  $('.progress-circle path').each(function() {
    var pathLength = this.getTotalLength();
    $(this).css('strokeDasharray', pathLength + ' ' + pathLength);
    $(this).css('strokeDashoffset', pathLength);
  });
}

circleReset();

// AJAX request for contact form
$(function () {
  $('#contact-form').submit(function (e) {
    e.preventDefault();

    let progress = 0;
    let form = $(this);
    let email = form.find('input[name="contact-email"]');
    let name = form.find('input[name="contact-name"]');
    let message = form.find('textarea[name="contact-message"]');
    let button = $('.progress-button');

    $.post('mail.php', {
      email: email.val(),
      name: name.val(),
      message: message.val(),
    }).done(function () {
      button.addClass('success');
      $('.confirmation-message').eq(0)
        .text('Your message has been successfully sent.')
        .animate({opacity: 1}, 300)
        .delay(2500)
        .animate({opacity: 0}, 300);

      email.val('');
      name.val('');
      message.val('');
    }).fail(function () {
      button.addClass('error');
      $('.confirmation-message').eq(0)
        .text('Something went wrong. Please wait and try again.')
        .animate({opacity: 1}, 300)
        .delay(2500)
        .animate({opacity: 0}, 300);
    }).always(function () {
      button.removeClass('loading');
    });

    button.removeClass(['success', 'error']);
    button.addClass('loading');
  });
});

document.addEventListener('DOMContentLoaded', () => {
    handleLogos();
});

const clampImage = (minScale, maxScale, minWidth, maxWidth) => {
    const images = document.querySelectorAll('.js-resize-logo'); // This selects all image elements. If you have a specific class or ID, use that instead.

    const resizeImages = () => {
        const screenWidth = window.innerWidth;
        images.forEach((img) => {
            const imgHeight = parseInt(img.getAttribute('height'));
            if (screenWidth >= minWidth && screenWidth <= maxWidth) {
                // Calculate the scale factor based on the current screen width
                const scaleFactor = minScale + (maxScale - minScale) * (screenWidth - minWidth) / (maxWidth - minWidth);
                img.style.height = scaleFactor * imgHeight + 'px';
            } else if (screenWidth < minWidth) {
                img.style.height = minScale * imgHeight + 'px';
            } else {
                img.style.height = maxScale * imgHeight + 'px';
            }
        });
    };

    // Call resize function initially and add event listener for future window resizes
    const handleResize = () => {
        // Store the window width
        let windowWidth = window.innerWidth;

        // Initial update
        resizeImages();

        // Resize Event
        window.addEventListener('resize', () => {
            // Check window width has actually changed, and it's not just iOS triggering a resize event on scroll
            if (window.innerWidth !== windowWidth) {
                // Update the window width for next time
                windowWidth = window.innerWidth;
                // Do stuff here
                resizeImages();
            }
            // Otherwise do nothing
        });
    };

    handleResize();
}

const handleLogos = () => {
    clampImage(0.75, 1, 768, 1440);
};
